import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

// css
import css from '../css/components/Event.module.css';

// cs dayjs locale
import '../utils/dayjs-cs-locale';
dayjs.locale('cs');

export default class Event extends React.Component {
  render() {
    const name = this.props.event.name;
    const startDate = this.props.event.startDate;
    const endDate = this.props.event.endDate;
    const location = this.props.event.location;
    const url = this.props.event.urlLink;
    const descriptionHtml = this.props.event.descriptionNode.childMarkdownRemark.html;

    const isPast = dayjs(endDate).isBefore(dayjs());

    var displayDate = '';

    if (startDate === endDate) {
      displayDate = dayjs(startDate).format('D. MMMM');
    } else {
      displayDate = dayjs(startDate).format('D-') + dayjs(endDate).format('D. MMMM');
    }

    const getClassNames = () => {
      return classNames(css.event, { [css.disabled]: isPast === true });
    };

    return (
      <article className={getClassNames()}>
        <div className={css.date}>{displayDate}</div>
        <h3 className={css.name}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </h3>
        <div className={css.location}>{location}</div>
        <div className={css.description} dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
      </article>
    );
  }
}
